import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/notification";
const STORE_NAME = "notifications";

export const fetchNotifications = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/all`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    // store.commit("setError", {
    //   isError: true,
    //   error: "Cannot fetch staff offline.",
    // });
  }
};

export const getNotificationsCount = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/count`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    // store.commit("setError", {
    //   isError: true,
    //   error: "Cannot fetch staff offline.",
    // });
  }
};

export const readNotification = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    // store.commit("setError", {
    //   isError: true,
    //   error: `Cannot fetch logs offline.`,
    // });
  }
};

export const subscribe = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/subscribe`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to enable ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to enable ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot enable notifications offline.`,
    });
  }
};

export const unsubscribe = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/unsubscribe`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to disable ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to disable ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot disable notifications offline.`,
    });
  }
};
