import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCrI4SHK8TJPbcg-OwKen-qbCOAeXOrLAI",
  authDomain: "imso-app-prod.firebaseapp.com",
  projectId: "imso-app-prod",
  storageBucket: "imso-app-prod.firebasestorage.app",
  messagingSenderId: "792544610207",
  appId: "1:792544610207:web:fabef825fb21c09eeddfaa",
  measurementId: "G-GT55C03B6Y",
});

const messaging = getMessaging(firebaseApp);
export { messaging };