<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list v-if="auth && auth.first_name && auth.last_name && auth.email">
        <v-list-item class="px-2 d-flex justify-center">
          <v-list-item-avatar
            :color="'#' + ((Math.random() * 0xffffff) << 0).toString(16)"
            size="60"
            class="mr-0 d-flex justify-center"
            style="position: relative"
          >
            <div class="white--text text-h5" style="position: absolute">
              {{ auth.first_name.charAt(0).toUpperCase()
              }}{{ auth.last_name.charAt(0).toUpperCase() }}
            </div>
            <v-img
              :src="auth.profile"
              alt="auth.first_name"
              v-if="auth.profile"
            />
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link class="text-center" @click="viewProfile">
          <v-list-item-content class="pt-0">
            <v-list-item-title class="text-h6">
              {{ auth.first_name }} {{ auth.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle> {{ auth.email }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>
        <v-sheet
          v-for="item in drawerItems"
          :key="item.title"
          color="transparent"
        >
          <v-list-group
            v-if="item.items && item.canAccess"
            :prepend-icon="item.action"
            no-action
            :group="item.group"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <v-list-item
              link
              v-for="child in item.items"
              :key="child.title"
              :to="{ name: child.pathName }"
              :inactive="child.inactive"
            >
              <v-list-item-icon v-if="child.action">
                <v-icon>{{ child.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            link
            v-else-if="item.canAccess"
            :to="{ name: item.pathName }"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-sheet>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      width="400"
      temporary
      right
      v-if="notification"
      v-model="notification"
    >
      <v-list-item class="px-2">
        <v-icon class="me-2">mdi-bell-outline</v-icon>
        <v-list-item-title>Notifications</v-list-item-title>

        <v-btn icon @click.stop="notification = !notification">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list three-line>
        <v-list-item
          v-for="(notificationx, x) in notifications"
          :key="x + 'noti'"
          @click="handleNotifi(notificationx)"
        >
          <v-list-item-content>
            <v-sheet color="transparent" class="text-subtitle-1 mb-0"
              >{{ notificationx.notifications.title }}

              <v-icon small v-if="notificationx.read_at">mdi-check</v-icon>
              <v-icon v-else color="green">mdi-new-box</v-icon>
            </v-sheet>
            <v-sheet color="transparent" class="text--secondary text-caption">{{
              $_formatTimestampX(notificationx.notifications.created_at)
            }}</v-sheet>
            <v-sheet color="transparent" class="text--secondary text-body-2">{{
              notificationx.notifications.message
            }}</v-sheet>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat class="app-dar" outlined>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-sheet class="d-flex align-center px-3 py-3" color="transparent">
        <!-- <v-img max-width="20" alt="IMSO" src="/images/Icon.png" /> -->
        <v-sheet
          class="text-h6 font-weight-bold primary--text"
          color="transparent"
          >IMSO</v-sheet
        >
      </v-sheet>
      <v-spacer></v-spacer>
      <v-badge
        color="green"
        :content="notificationCount"
        overlap
        offset-y="24"
        offset-x="17"
        v-if="notificationCount > 0"
      >
        <v-btn
          icon
          @click="
            notification = !notification;
            getNotifi();
          "
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
      <v-btn
        icon
        @click="
          notification = !notification;
          getNotifi();
        "
        v-else
      >
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <v-card outlined elevation="0" class="imso-menu">
          <v-list class="pb-0">
            <v-list-item>
              <v-list-item-title>Enable dark theme</v-list-item-title>
              <v-list-item-action>
                <v-switch v-model="isDark" dense></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Update app</v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="$router.go()">
                  <v-icon>mdi-update</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Reset</v-list-item-title>
              <v-list-item-action>
                <v-btn icon color="error" @click="resetX()">
                  <v-icon>mdi-restart</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Logout</v-list-item-title>
              <v-list-item-action>
                <v-btn icon color="error" @click="logout()">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <!-- <v-list-item>
              <v-list-item-action>
                <v-switch v-model="hints"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Enable notifications</v-list-item-title>
            </v-list-item> -->
          </v-list>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="error" text @click="logout()">
              <v-icon>mdi-logout</v-icon> Logout
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-banner single-line v-if="!isOnline">
        <div
          class="d-flex justify-center align-center secondary--text text-caption"
        >
          <v-icon slot="icon" color="secondary" class="me-2" small>
            mdi-cloud-off-outline
          </v-icon>
          You are offline.
        </div>
      </v-banner>
      <v-breadcrumbs :items="breadcrumbsItems" divider=".">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-container fluid>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>

      <v-snackbar
        bottom
        left
        v-model="isMessage"
        timeout="5000"
        :vertical="error.isError"
        color="snackBar"
      >
        <v-icon class="me-2" color="error" v-if="error.isError">
          mdi-alert-circle-outline
        </v-icon>
        <v-icon class="me-2" color="primary" v-else>
          mdi-check-circle-outline
        </v-icon>
        {{ error.error }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="error.isError ? 'error' : 'primary'"
            text
            v-bind="attrs"
            @click="isMessage = false"
          >
            {{ error.isError ? "Close" : "OK" }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <v-footer class="d-flex justify-end foot-dar" outlined>
      <v-sheet
        class="font-weight-light text-caption grey--text"
        color="transparent"
      >
        © 2024, OPEnE. All rights reserved. IMSO version Beta {{ $version }}
      </v-sheet>
    </v-footer>

    <!-- Delete doc confirmation dialog -->
    <v-dialog v-model="sync" persistent max-width="220">
      <v-card outlined>
        <v-card-text class="d-flex flex-column align-center mt-4">
          Syncing offline changes.
          <v-progress-circular
            :size="80"
            indeterminate
            color="primary"
            width="1"
            class="mt-3"
          >
            <v-icon x-large color="primary"> mdi-sync </v-icon>
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { syncDB } from "../syncDB";
import { reset } from "../reset";
import { messaging } from "../firebase";
import { getToken, onMessage } from "firebase/messaging";
import {
  getNotificationsCount,
  fetchNotifications,
  readNotification,
  subscribe,
} from "@/services/notificationsService.js";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    error: (state) => state.error.data,
    settings: (state) => state.settings.data,
    sync: (state) => state.sync.data,
  }),
  components: {
    //
  },
  data: () => ({
    isOnline: navigator.onLine,
    isMessage: false,
    isDark: false,
    breadcrumbsItems: [],
    drawer: window.innerWidth > 1264,
    drawerItems: [],
    notification: false,
    notificationCount: 0,
    notifications: [],
  }),
  watch: {
    isDark(val) {
      if (val) {
        document.documentElement.style.setProperty("--scrollbar-bg", "#22272e");
        document.documentElement.style.setProperty(
          "--scrollbar-thumb",
          "#2d333b"
        );
        document.documentElement.style.setProperty(
          "--scrollbar-border",
          "#ffffff31"
        );
      } else {
        document.documentElement.style.setProperty("--scrollbar-bg", "#f4f4f4");
        document.documentElement.style.setProperty(
          "--scrollbar-thumb",
          "#bdbdbd"
        );
        document.documentElement.style.setProperty(
          "--scrollbar-border",
          "#d7d7d7"
        );
      }
      this.$vuetify.theme.dark = val;
      this.$store.commit("updateSettings", {
        isDark: val,
      });
    },
    error(newError) {
      if (newError) {
        this.isMessage = true;
      }
    },
    isMessage(newValue) {
      if (newValue === false) {
        this.$store.dispatch("resetError");
      }
    },
  },
  async created() {
    this.registerForNotifications();
    await syncDB();
    await this.getNotifi();
    if (this.settings) {
      this.isDark = this.settings.isDark;
      this.$vuetify.theme.dark = this.settings.isDark;
    } else {
      this.isDark = this.$vuetify.theme.dark;
      this.$store.commit("updateSettings", {
        isDark: this.isDark,
      });
    }
    this.breadcrumbsItems = this.$router.currentRoute.meta.breadcrumbsItems;

    this.drawerItems = [
      {
        action: "mdi-view-dashboard-outline",
        title: "Dashboard",
        pathName: "PageDashboard",
        canAccess: true,
      },
      {
        action: "mdi-file-document-multiple-outline",
        group: ".*log.*",
        items: [
          {
            action: "mdi-counter",
            title: "Vehicle Running",
            pathName: "PageMyVehicleRunningList",
            canAccess: true,
          },
          {
            action: "mdi-gas-station-outline",
            title: "Vehicle Fuel",
            pathName: "PageMyVehicleFuelList",
            canAccess: true,
          },
          {
            action: "mdi-wrench-outline",
            title: "Vehicle Repair",
            pathName: "PageMyVehicleRepairList",
            canAccess: true,
          },
          // {
          //   action: "mdi-alert-box-outline",
          //   title: "Security Incidents",
          //   pathName: "",
          //   canAccess: true,
          //   inactive: true,
          // },
        ],
        title: "Logs",
        canAccess: true,
      },
      {
        action: "mdi-calendar-multiselect-outline",
        group: ".*(leave|lieu).*",
        items: [
          {
            // action: "mdi-counter",
            title: "Summary",
            pathName: "PageMyLeaveSummary",
            canAccess: true,
          },
          {
            // action: "mdi-counter",
            title: "Leave Application",
            pathName: "PageMyLeaveApplicationList",
            canAccess: true,
          },
          {
            // action: "mdi-gas-station-outline",
            title: "Lieu Application",
            pathName: "PageMyLieuApplicationList",
            canAccess: true,
          },
        ],
        title: "Leaves",
        canAccess: true,
      },
      {
        action: "mdi-account-multiple-outline",
        title: "Staff",
        pathName: "PageStaffList",
        canAccess: this.$_checkPermission(
          this.auth.permissions,
          "Manage Staff",
          "Read"
        ),
      },
      {
        action: "mdi-car-outline",
        title: "Vehicles",
        pathName: "PageVehicleList",
        canAccess: this.$_checkPermission(
          this.auth.permissions,
          "Manage Vehicle",
          "Read"
        ),
      },
      {
        action: "mdi-briefcase-outline",
        title: "Projects",
        pathName: "PageProjectList",
        canAccess: this.$_checkPermission(
          this.auth.permissions,
          "Manage Projects",
          "Read"
        ),
      },
      {
        action: "mdi-printer-outline",
        group: ".*report.*",
        items: [
          {
            // action: "mdi-file-document-outline",
            title: "Mileage per Project",
            pathName: "PageReportMPP",
            canAccess: true,
          },
          {
            // action: "mdi-file-document-outline",
            title: "Private Vehicle Mileage",
            pathName: "PageReportPVM",
            canAccess: true,
          },
          {
            // action: "mdi-file-document-outline",
            title: "Private Use Mileage",
            pathName: "PageReportPUM",
            canAccess: true,
          },
          // {
          //   // action: "mdi-file-document-outline",
          //   title: "Security Incident Reports",
          //   pathName: "",
          //   canAccess: true,
          //   inactive: true,
          // },
        ],
        title: "Reports",
        canAccess: this.$_checkPermission(
          this.auth.permissions,
          "Reports",
          "Generate"
        ),
      },
      {
        action: "mdi-shield-account-outline",
        title: "Roles",
        pathName: "PageRoleList",
        canAccess: this.$_checkPermission(
          this.auth.permissions,
          "Manage Role",
          "Read"
        ),
      },
    ];
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.listenForNotifications();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  updated() {
    this.breadcrumbsItems = this.$router.currentRoute.meta.breadcrumbsItems;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "PageAuthSignin" });
    },
    async updateOnlineStatus() {
      this.isOnline = navigator.onLine;
      if (this.isOnline) await syncDB();
    },
    viewProfile() {
      if (this.$route.name == "PageViewStaff") {
        if (this.$route.path != `/staff/${this.auth.uuid}`) {
          this.$router.replace({
            params: { uuid: this.auth.uuid },
            query: { bc: this.auth.first_name + " " + this.auth.last_name },
          });
          const { fullPath } = this.$route;
          this.$router.replace("/temporary-route").then(() => {
            this.$router.replace(fullPath);
          });
        }
      } else {
        this.$router.push({
          name: "PageViewStaff",
          params: { uuid: this.auth.uuid },
          query: { bc: this.auth.first_name + " " + this.auth.last_name },
        });
      }
    },
    async getNotifi() {
      this.notificationCount = await getNotificationsCount();
      this.notifications = await fetchNotifications();
    },
    async handleNotifi(notificationy) {
      this.notification = false;
      if (
        this.$router.currentRoute.path !=
        JSON.parse(notificationy.notifications.data).link
      ) {
        this.$router.push(JSON.parse(notificationy.notifications.data).link);
      }
      await readNotification(notificationy.uuid);
      await this.getNotifi();
    },
    resetX() {
      reset();
    },
    async registerForNotifications() {
      try {
        // Request permission for notifications
        await Notification.requestPermission();

        if (Notification.permission === "granted") {
          // Get FCM token
          const token = await getToken(messaging, {
            vapidKey:
              "BJKc5FNq_3T0J6hqgRbSyEmzyusOTTejCIUBNGJV9nfMDi_OcVK7u1NBe5e4G-V3ZGoJbFqQHp7uMNl5flvIVBs",
          });

          await subscribe({
            firebase_player_id: token,
          });
        } else {
          console.warn("Permission not granted for notifications.");
        }
      } catch (error) {
        console.error("Error during notification registration:", error);
      }
    },

    listenForNotifications() {
      // Foreground notifications
      onMessage((payload) => {
        console.log("Message received in foreground:", payload);

        // Extract notification data
        const title = payload.notification?.title || "IMSO Notification";
        const body = payload.notification?.body || "You have a notification from IMSO";
        const icon =
          payload.notification?.icon || "/logo.png"; // Default icon path
        const link = payload.data?.link || "/"; // Fallback link

        // Display the notification
        const notification = new Notification(title, {
          body,
          icon,
        });

        this.getNotifi();

        // Handle click event
        notification.onclick = () => {
          // Open the link in a new tab or window
          window.open(link);
        };
      });
    },
  },
};
</script>

<style>
.app-dar {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}

.foot-dar {
  border-bottom: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
</style>